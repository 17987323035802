<template>
  <div>
    <AppTable
      store-module="contact_us"
      :table-fields="tableFields"
      :filters="filters"
      :actions="actions"
      :table-header="tableHeader"
    />
  </div>
</template>

<script>
import AppTable from "@/components/table/AppTable.vue";
import i18n from "@/libs/i18n/index";

export default {
  name: "ContactUs",
  components: {
    AppTable,
  },
  data() {
    return {
      tableFields: [
        { key: "edit_delete", label: i18n.t("actions") || "Actions" },
        { key: "name", label: i18n.t("name") || "name" },
        { key: "mobile", label: i18n.t("mobile") || "mobile" },
        { key: "message", label: i18n.t("message") || "message" },
        { key: "is_read", label: i18n.t("is_read") || "is_read" },
      ],
      filters: [{ name: "createdAt", componentType: "AppDatePicker" }],
      actions: [
        {
          label: i18n.t("is_read") || "Is Read",
          variant: "success",
          event: "is_read",
          condition: "is_read",
          storeAction: "turnToReadHandler",
          message:
            i18n.t("are_you_sure_to_turn_to_read") ||
            "Are you sure to turn to read?",
          message_key: "message",
        },
      ],
      tableHeader: {
        addNewBtn: false,
        multiKeySearch: true,
      },
    };
  },
};
</script>
